.work-hours {
  display       : flex;
  flex-direction: column;
  font-size     : 16px;
  position      : relative;

  .work-hours-header {
    padding-top     : 15px;
    background-color: #F5F6F7;

    .header-container {
      background-color: #FFF;
      display         : flex;
      justify-content : space-between;
      align-items     : center;
      padding         : 15px;

      .right-export {
        color           : #FFF;
        border-radius   : 1px;
        padding         : 10px 19px;
        font-size       : 16px;
        background-color: #3370FF;
        transition      : all 0.3s ease-in-out;

        &:hover {
          opacity   : 0.7;
          box-shadow: 0px 4px 10px 0px rgba(51, 112, 255, 0.5);
        }
      }
    }
  }

  .work-hours-container {
    background-color: #F5F6F7;
    padding         : 0 16px 32px 16px;
    flex            : 1;
    box-sizing      : border-box;
    overflow-y      : auto;

    .table {
      .table-container {
        .title-info {
          background-color: #FFF;
          padding         : 24px 34px 0;

          .info-container {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            .left {
              .user {
                display: flex;

                .avatar {
                  margin-right: 7px;
                  width       : 26px;
                  height      : 26px;

                  .all-user-icon {
                    font-size  : 24px;
                    font-family: 'iconfont';
                  }

                  .img {
                    width        : 26px;
                    height       : 26px;
                    border-radius: 50%;
                  }
                }

                .name {
                  font-size  : 15px;
                  color      : #1F2329;
                  display    : flex;
                  align-items: center;
                }
              }

              .current-total {
                margin-top: 8px;
                color     : #646A73;
                font-size : 12px;
              }
            }
          }
        }

        padding-top: 16px;
      }
    }

    .desc {
      .desc-container {
        padding-top: 16px;
      }
    }

    .data-pie-chart {
      background-color: #fff;
      // margin          : 0 16px;
      margin-top      : 16px;
      border-radius   : 2px;

      .data-pie-chart-box {
        display         : flex;
        background-color: #fff;
        margin          : 0 16px;
        margin-top      : 16px;
        border-radius   : 2px;
        position        : relative;

        .pie-container {
          width : 480px;
          height: 400px;
        }

        .project-table {
          flex   : 1;
          padding: 50px 40px 50px 0;

          .row {
            display: flex;

            .item {
              flex           : 1;
              font-size      : 14px;
              justify-content: center;
              padding        : 15px 0;
              display        : flex;
              align-items    : center;

              &.name {
                padding-left   : 18px;
                justify-content: flex-start;

                .name-val {
                  flex: 1;
                }
              }

              .color-block {
                width        : 16px;
                height       : 13px;
                background   : #ddd;
                border-radius: 2px;
                margin-right : 6px;
              }
            }
          }

          .header {
            border-bottom: solid 2px #b3b3b3;

            .item {
              font-weight: bold;

              .icon-font {
                font-size: 16px;
              }
            }
          }

          .tbody .row {
            .item {
              color: #1F2329;
            }
          }

          .project-cell {
            transition   : all .3s ease-out;
            border-bottom: solid 1px #E8E8E8;
            cursor       : pointer;

            &:hover {
              border-bottom: solid 1px transparent;
              background   : #F6F7F8;
            }
          }
        }
      }
    }

    .my-data-title {
      color      : #1F2329;
      font-size  : 18px;
      font-weight: 700;
      padding    : 25px 25px 0;
    }

    .empty-data {
      font-size      : 18px;
      position       : absolute;
      top            : 0;
      left           : 0;
      width          : 100%;
      height         : 100%;
      background     : #fff;
      display        : flex;
      justify-content: center;
      align-items    : center;
      color          : #1F2329;
    }
  }
}



.icon-font {
  font-family: "iconfont" !important;
}
.hours-table {
  background-color: #FFF;
  position: relative;
  padding: 24px 34px 35px;
  .table {
    border: solid 1px #EFF0F1;
    border-bottom: none;
    width: max-content;
    min-width: 100%;
    box-sizing: border-box;
    .row {
      width: min-content;
      min-width: 100%;
      display: flex;
      .col {
        flex: 1;
        display: inline-block;
        min-width: 85px;
        border-right: solid 1px #EFF0F1;
        vertical-align: top;
        &:last-child {
          border-right: none;
        }
      }
    }
    .table-header {
      border-bottom: solid 1px #EFF0F1;
      .header-item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // padding: 14px 0;
        height: 60px;
        color: #1F2329;
        font-weight: bold;
        flex-direction: column;
        .week {
          font-size: 12px;
        }
        .date {
          font-size: 10px;
        }
      }
    }
    .add-row {
      border-bottom: solid 1px #EFF0F1;

      .add-icon {
        box-sizing: border-box;
        text-align: center;
        color: #E4E5E7;
        font-size: 14px;
        cursor: pointer;
        width: 100%;
        &:hover, &.today {
          // opacity: 0.7;
          color: #3370FF;
        }
      }
    }
    .table-item {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
      .date-block {
        height: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
      .add-block {
        font-size: 14px;
        color: #3370FF;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-font {
          transition: all .3s ease-out;
          opacity: 0;
          transform: scale(0);
        }
        &:hover {
          .icon-font {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
    .table-body {
      &.hasBB {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
          background-color: #EFF0F1;
        }
      }
      .add-item {
        height: 35px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .extra-view {
    display: flex;
    .row-titles {
      flex: 1;
      .table {
        border-color: transparent;
        .table-header, .add-row  {
          border-color: transparent;
        }
      }
    }
    .table-item {
      &.project {
        &:hover {
          color: #3370FF;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &.user {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // cursor: pointer;
        .avatar {
          width: 34px;
          height: 34px;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 34px;
          }
        }
        .name {
          padding-left: 12px;
          flex: 1;
        }
      }
    }
  }
  .table-new {
    font-size: 14px;
    display: flex;
    &.hasBB {
      border-bottom: 1px solid #EFF0F1;
    }
    .table-left {
      min-width: 240px;
      .row-container {
        &:first-child {
          height: 50px;
        }
        &:nth-child(2) {
          height: 34px;
        }
        &.hidden-add-row {
          height: 0;
          visibility: hidden;
        }
        height: 48px;
        .table-left-row {
          display: flex;
          height: 100%;
          .first-blank {
            flex: 1;
            color: transparent;
            box-sizing: border-box;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
          }
          .first-total {
            flex: 1;
            color: #1F2329;
            text-align: center;
            line-height: 50px;
            box-sizing: border-box;
            border-top: 1px solid #E8E8E8;
            border-bottom: 1px solid #E8E8E8;
            border-right: 1px solid #E8E8E8;
          }
          .second-blank {
            flex: 1;
            color: transparent;
            box-sizing: border-box;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
          }
          .second-total {
            flex: 1;
            color: transparent;
            box-sizing: border-box;
            border-bottom: 1px solid #E8E8E8;
            border-right: 1px solid #E8E8E8;
          }
          .project-name {
            flex: 1;
            line-height: 47px;
            color: #1F2329;
            font-size: 12px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
            .name-text {
              &:hover {
                color: #3370FF;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .member-cell {
            flex: 1;
            line-height: 47px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            color: #1F2329;
            font-size: 12px;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
            .container {
              margin-left: 12px;
              display: flex;
              align-items: center;
              .avatar {
                width: 34px;
                height: 34px;
                .img {
                  width: 100%;
                  height: 100%;
                  border-radius: 34px;
                }
              }
              .name {
                padding-left: 12px;
              }
            }
          }
          .project-total {
            flex: 1;
            padding: 14px 0;
            color: #051C25;
            font-size: 14px;
            text-align: center;
            box-sizing: border-box;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
            &.last {
              border-bottom: 1px solid #E8E8E8;
            }
          }
        }
      }
    }
    .table-right {
      flex: 1;
      overflow-x: auto;
      .table {
        border-left: none;
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 16px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: #dddfe1;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
      }
      .row-container {
        &:first-child {
          height: 50px;
        }
        &:nth-child(2) {
          height: 34px;
        }
        &.hidden-add-row {
          height: 0;
          visibility: hidden;
        }
        height: 48px;
        .table-right-row {
          display: flex;
          height: 100%;
          .week-date {
            text-align: center;
            flex: 1;
            min-width: 83px;
            padding: 15px 0px 5px 10px;
            color: #1F2329;
            box-sizing: border-box;
            border-top: 1px solid #E8E8E8;
            border-bottom: 1px solid #E8E8E8;
            border-right: 1px solid #E8E8E8;
            .weeklabel {
              font-size: 12px;
            }
            .day {
              font-size: 10px;
            }
          }
          .add-icon {
            flex: 1;
            min-width: 83px;
            box-sizing: border-box;
            text-align: center;
            color: #E4E5E7;
            font-size: 14px;
            padding: 8px 0;
            border-bottom: 1px solid #E8E8E8;
            border-right: 1px solid #E8E8E8;
            cursor: pointer;
            &:hover {
              // opacity: 0.7;
              color: #3370FF;
            }
            &.first {
              border-bottom: 1px solid transparent;
              min-width: 114px;
            }
            &.today {
              color: #3370FF;
            }
          }
          .hour-label {
            cursor: default !important;
            flex: 1;
            min-width: 83px;
            // padding: 15px 0px 14px 0;
            font-size: 14px;
            box-sizing: border-box;
            color: #FFF;
            border-bottom: 1px solid transparent;
            border-right: 1px solid #E8E8E8;
            .box {
              position: relative;
              display: none;
              background-color: #FFF;
            }
            display: flex;
            align-items: center;
            .hour-box {
              flex: 1;
              height: 35px;
              line-height: 35px;
              text-align: center;
            }
            &.label-hover-class {
              cursor: pointer !important;
            }
            .hover-add-icon {
              min-width: 83px;
              box-sizing: border-box;
              text-align: center;
              color: transparent;
              font-size: 14px;
              padding: 8px 0;
              cursor: pointer;
              &:hover {
                // opacity: 0.7;
                color: #3370FF;
              }
              &.today {
                color: #3370FF;
              }
            }
            &.is-zero {
              color: transparent;
            }
            &.last {
              border-bottom: 1px solid #E8E8E8;
            }
          }
        }
      }
    }
  }
  .bubble-box {
    position: absolute;
    visibility: hidden;
    &.visibled {
      visibility: visible;
    }
    .bubble-container {
      width: 228px;
      display: flex;
      flex-direction: column;
      padding: 21px 15px;
      background-color: #FFF;
      .header {
        display: flex;
        justify-content: space-between;
        color: #1F2329;
        align-items: center;
        .week-day {
          font-size: 14px;
          font-weight: bold;
        }
        .user {
          font-size: 12px;
          display: flex;
          align-items: center;
          .name {
            margin-right: 3px;
          }
          .avatar {
            width: 24px;
            height: 24px;
            .img-icon {
              width: 24px;
              height: 24px !important;
              border-radius: 24px;
            }
          }
        }
      }
      .body {
        margin-top: 26px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #E8E8E8;
        font-size: 12px;
        .control {
          display: flex;
          justify-content: space-between;
          padding-right: 12px;
          color: #6F757E;
          font-size: 12px;
          margin-top: 8px;
          .hour-remark {
            width: 75%;
            display: flex;
            line-height: 1.5;
            flex: 1;
            .icon {
              margin-right: 4px;
            }
            .remark {
              max-width: 65%;
            }
          }
          .hour {
            width: 20%;
            display: flex;
            line-height: 1.5;
            .icon {
              margin-right: 4px;
            }
          }
        }
      }
      .member-body {
        margin-top: 26px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #E8E8E8;
        font-size: 12px;
        .control {
          display: flex;
          justify-content: space-between;
          padding-right: 12px;
          color: #6F757E;
          font-size: 12px;
          margin-top: 8px;
          .project {
            width: 75%;
            display: flex;
            line-height: 1.5;
            .icon {
              margin-right: 4px;
            }
            .name {
              .name-text {
                &:hover {
                  color: #3370FF;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }
          }
          .hour {
            width: 20%;
            display: flex;
            line-height: 1.5;
            .icon {
              margin-right: 4px;
            }
          }
        }
      }
      .member-footer {
        margin-top: 15px;
        color: #2B2F34;
        font-size: 18px;
      }
      .bubble-dialog-footer {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .total {
          color: #2B2F34;
          font-size: 18px;
        }
        .edit-btn {
          color: #FFF;
          font-size: 12px;
          background-color:#3370FF;
          border-radius: 2px;
          padding: 5px 11px;
        }
      }
    }
  }
}

.hour-tips {
  position: absolute;
  transform: translateX(calc(-50% + 8px)) translateY(calc(-100% - 6px));
  .hour-tips-container {
    .tips-text {
      background-color: #1661A8;
      color: #FFF;
      padding: 2px 4px;
      border-radius: 1px;
      white-space: nowrap;
      font-size: 14px;
      position: relative;
      .triangle-container {
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        .triangle {
          width: 0;
          height: 0;
          border-width: 4px 4px 0px 4px;
          border-style: solid;
          border-color: #1661A8 transparent transparent;
        }
      }
    }
  }
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
  &.visible {
    pointer-events: all;
    visibility: visible;
    .container {
      transform: translateX(calc(-100% + 10px)) translateY(10px) scale(1);
      opacity: 1;
    }
  }
  .container {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    box-shadow:0px 0px 6px 0px rgba(178,178,178,0.5);
    border-radius: 4px;
    transform: translateX(calc(-100% + 20px)) translateY(10px) scale(0);
    transform-origin: 100% 0%;
    transition: transform .3s ease-out, opacity .3s ease-out;
    opacity: 0;
    &:after {
      content: '';
      display: block;
      border-style: solid;
      border-width: 10px;
      border-color: transparent;
      border-bottom-color: #fff;
      position: absolute;
      top: -20px;
      right: 5px;
    }
  }
}

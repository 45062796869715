.base-bubble-dialog {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255,255,255,1);
  box-shadow: 0px 0px 7px 0px rgba(178,178,178,0.5);
  border-radius: 4px;
  z-index: 999;
  &.top {
    bottom: 100%;
    margin-bottom: 8px;
    .triangle-container {
      position: absolute;
      padding-left: 50%;
      bottom: -8px;
      .triangle {
        width: 0;
        height: 0;
        border-width: 8px 8px 0px;
        border-style: solid;
        border-color:  rgba(255,255,255,1) transparent  transparent;
      }
    }
  }
  &.right {
    left: 100%;
    transform: translateY(-50%);
    margin-left: 8px;
    .triangle-container {
      position: absolute;
      transform: translateY(80%);
      padding-bottom: 50%;
      left: -8px;
      .triangle {
        width: 0;
        height: 0;
        border-width: 8px 8px 8px 0;
        border-style: solid;
        border-color:  transparent rgba(255,255,255,1) transparent;
      }
    }
  }
  &.bottom {
    top: 100%;
    margin-top: 8px;
    .triangle-container {
      position: absolute;
      padding-left: calc(50% - 16px);
      padding-right: calc(50% - 16px);
      top: -16px;
      .triangle {
        width: 0;
        height: 0;
        border-width: 0 16px 16px;
        border-style: solid;
        border-color:  transparent transparent rgba(255,255,255,1) transparent;
        transform: translateY(25%);
      }
    }
  }
  &.left {
    right: 100%;
    transform: translateX(-180%) translateY(-50%);
    margin-right: 8px;
    .triangle-container {
      position: absolute;
      transform: translateY(80%);
      padding-bottom: 50%;
      right: -8px;
      .triangle {
        width: 0;
        height: 0;
        border-width: 8px 0 8px 8px;
        border-style: solid;
        border-color:  transparent transparent transparent rgba(255,255,255,1);
      }
    }
  }
}

.base-button{
  outline: none;
  border: none;
  list-style: none;
  background: none;
  color: #333;
  font-size: 14px;
  line-height: 1;
  border-radius: 2px;
  cursor: pointer;
  &::after{
    border:none;
  }
  &:focus {
    border: none;
    outline: none;
  }
  min-width: 100px;
  &.primary {
    color: #FFF;
    background-color: #2F54EB;
  }
}

.pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #646A73;
  .total {
    margin-right: 4px;
  }
  .pages {
    margin: 0 6px;
  }
  .item {
    margin: 0 4px;
    cursor: pointer;
    &.active {
      color: #057AFF;
    }
  }
  .pages {
    display: flex;
  }
  .jump {
    align-items: center;
    display: flex;
    .label {
      display: inline-block;
    }
    .page-input {
      width: 50px;
      margin: 0 4px;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid #dee0e3;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      transition: all 0.3s ease-out;
      min-height: 36px;
      box-sizing: border-box;
    }
  }
}

@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'iconfont';  /* project id 1527339 */
  src: url('//at.alicdn.com/t/font_1527339_989lxnertkf.eot');
  src: url('//at.alicdn.com/t/font_1527339_989lxnertkf.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_1527339_989lxnertkf.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_1527339_989lxnertkf.woff') format('woff'),
  url('//at.alicdn.com/t/font_1527339_989lxnertkf.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_1527339_989lxnertkf.svg#iconfont') format('svg');
}
@font-face {
  font-family: 'Oswald Medium';
  src: url('https://develop-stash.xiliutech.com/font/Oswald-Medium.woff') format('woff'),
  url('https://develop-stash.xiliutech.com/font/Oswald-Medium.ttf') format('truetype');
}

.icon-font {
  font-family: "iconfont" !important;
  font-style: normal;
}

.base-page {
  height: 100%;
  overflow: auto;
}

.base-hover {
  cursor: pointer;
}
.base-hover:hover {
  opacity: 0.7;
}
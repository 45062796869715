.hour-container {
  box-sizing: border-box;
  max-height: 75vh;
  overflow: auto;
  font-size: 16px;
  .horus-form {
    padding: 16px 16px 29px;
    .delete-link {
      width: 100%;
      color: #3370ff;
      font-size: 14px;
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-end;
      .delete-text {
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .control {
      display: flex;
      align-items: flex-start;
      .icon {
        font-size: 0;
        width: 20px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icon-font {
          font-size: 16px;
          color: #646a73;
        }
      }
      .input-box {
        flex: 1;
        .custom-input-class {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: solid 1px #e4e5e7;
          padding: 8px 11px;
          border-radius: 4px;
          font-size: 14px;
          height: 52px;
          resize: none;
          &:hover {
            border-color: #3370ff !important;
          }
          &::placeholder {
            color: #9CA2A9;
          }
          &:focus {
            border-color: #3370ff !important;
            outline: none;
          }
        }
      }
      .required {
        color: #FF0000;
        width: 20px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.hours {
        padding: 16px 0;
      }
      .selector-box {
        flex: 1;
      }
    }
  }
}
.footer {
  padding: 28px 16px 16px;
  display: flex;
  font-size: 14px;
  .total-hours {
    flex: 1;
    font-size: 14px;
    color: #3370ff;
    display: flex;
    align-items: center;
  }
  .reset {
    margin-right: 12px;
  }
}

.input-component-wrapper {
  padding: 0;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  font-size: 0;
  display: inline-flex;
  width: 100%;
  align-items: center;
  transition: all .3s ease-out;
  height: 38px;
  box-sizing: border-box;
  &:hover {
    border-color: #3370FF;
  }
  .input-component {
    padding-left: 8px;
    border: none;
    font-size: 14px;
    width: 100%;
    outline: none;
  }
}

.base-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  overflow: auto;
  &.visibled {
    visibility: visible;
    .body {
      opacity: 1;
      transition: all .3s ease-out;
      transform: translateY(0px);
    }
  }
  .body {
    transition: none;
    transform: translateY(-20px);
    opacity: 0;
    background-color: #FFF;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    .header {
      border-bottom: 1px solid #F5F6F7;
      color: #1F2329;
      font-size: 14px;
      text-align: center;
      position: relative;
      padding: 18px 0;
      .close-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        .icon-font.icon {
          font-family: 'iconfont' !important;
          font-size: 12px;
          color: #646A73;
        }
      }
    }
    .content {
      flex: 1;
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      .save {
        margin-right: 10px;
        margin-left: 8px;
      }
    }
  }
}

.head {
  padding: 20px 16px;
  border-top: 16px solid #f5f6f7;
  display: flex;
  align-items: center;
  justify-content: center;
  .filter-form {
    display: flex;
    flex: 1;
    .input-name {
      font-size: 0;
      margin-left: 12px;
      .search-icon {
        font-size: 16px;
        color: #DEE0E3;
        margin-left: 10px;
      }
    }
    .select-picker-wrap {
      width: 150px;
    }
  }

  .add-button {
    background-color: #3370FF;
    border: none;
    color: #fff;
    padding: 10px 15px;
    font-size: 14px;
    box-shadow: 0 4px 10px 0 rgba(51, 112, 255, 0.5);
    border-radius: 4px;
    &:hover {
      opacity: 0.7;
    }
    .icon-font {
      font-size: 14px;
    }
    .add-icon {
      margin-right: 5px;
    }
    &:after {
      display: none;
    }
  }
}

.project-container {
  display: flex;
  flex-direction: column;
  .list-container {
    background: #F5F6F7;
    flex: 1;
    // padding: 16px;
    box-sizing: border-box;
    overflow: auto;
    overflow-x: hidden;
    .container {
      box-sizing: border-box;
      margin-top: 16px;
      padding: 31px 16px 16px;
      box-sizing: border-box;
      background: #fff;
      .table-header {
        background: #F5F6F7;
        font-weight: medium;
      }
      .row {
        font-size: 14px;
        display: flex;
        padding: 20px 0;
        justify-content: space-between;
        color: #646A73;
        .item {
          flex: 1;
          text-align: center;
          &.overtime {
            color: #F20000;
          }
          &.date-range {
            width: 150px;
          }
        }
        .action {
          display: flex;
          align-items: top;
          justify-content: center;
          .icon-font {
            font-size: 16px;
            color: #666;
            cursor: pointer;
            color: #3370FF;
          }
        }
      }
      .table-body {
        border-left: 1px solid #eff0f1;
        border-right: 1px solid #eff0f1;
        .row {
          color: #646A73;
          position: relative;
          border-bottom: solid 1px #EFF0F1;
          .archived-tag {
            position: absolute;
            background: #C9C9C9;
            color: #fff;
            top: 0;
            left: 0;
            padding: 3px 6px;
            font-size: 11px;
            &:after {
              position: absolute;
              content: '';
              width: 5px;
              height: 100%;
              right: -5px;
              top: 0;
              background: #C9C9C9;
              clip-path: polygon(0 0, 100% 50%, 0 100%);
            }
          }
          &.archived {
            .item {
              color: #A8A8A8;
            }
          }
          .name-text {
            &:hover {
              color: #3370FF;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      .pagination {
        margin-top: 20px;
      }
    }

    .empty {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .empty-image {
        width: 133px;
      }
      .empty-tips {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
      }
    }
  }
}

.action-popover {
  .item {
    padding: 0 10px;
    font-size: 12px;
    margin-bottom: 10px;
    color: #646A73;
    cursor: pointer;
    &:hover {
      color: #3370FF;
    }
    &.primary {
    }
    &.delete {
      color: #f54a45;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.add-footer {
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-end;
  font-size: 0;
}
.project-form {
  padding: 16px;
}
.line {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
  justify-content: center;
  .icon {
    // margin-right: 6px;
    font-size: 0;
    width: 20px;
    height: 38px;
    display: flex;
    align-items: center;
    .icon-font {
      font-size: 16px;
      color: #1f2329;
    }
  }
  .control {
    font-size: 0;
    flex: 1;
    align-items: center;
    .date-container {
      width: 30%;
    }
    .separate {
      height: 1px;
      width: 14px;
      background-color: #1F2329;
      margin: 0 9px;
    }
    &.between {
      justify-content: flex-start;
      display: flex;
    }
    .input {
      padding: 0 12px;
    }
  }
  .suffix {
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 10px;
    color: #646A73;
  }
  .label {
    font-size: 14px;
    color: #646A73;
    margin-top: 6px;
    font-weight: 400;
    &.required {
      &::after {
        content: '*';
        color: #ff0000;
        font-size: 14px;
      }
    }
  }
}

.tips {
  color: #f54a45;
  margin-bottom: 16px;
  font-size: 14px;
  padding-left: 30px;
}

.title-info {
  background-color: #FFF;
  padding-bottom: 22px;
  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .all-member {
        font-size: 24px;
        color: #2B2F34;
        .icon-font {
          font-size: 24px;
        }
        .all-text {
          padding-left: 6px;
        }
      }
    }
  }
}

.required {
  font-size: 16px;
  color: #FF0000;
  width: 20px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

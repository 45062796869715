.contact_picker_modal {
  display: flex;
  .select_container, .selected {
    flex: 1;
    height: 300px;
  }
  .select_container {
    border-right: dashed 1px #ddd;
    padding-right: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .search {}
    .list {
      flex: 1;
      overflow-y: auto;
    }
  }
  .selected {
    overflow: auto;
    .contact_item {
      padding-left: 10px;
    }
  }
  .contact_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background: #f6f6f6;
    }
    &.selected_item:not(.fixed) {
      position: relative;
      overflow: hidden;
      &:hover {
        &::after {
          content: '\e648';
          font-family: 'iconfont';
          width: 50%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20px;
          color: #666;
        }
      }
    }
    .check_box {
      margin-right: 10px;
      padding-left: 10px;
    }
    .avatar {
      width: 44px;
      height: 44px;
      background: #efefef;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .name {
      font-size: 16px;
      margin-left: 10px;
    }
  }
}
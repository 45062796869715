.hour-color {
  display: flex;
  flex-direction: column;
  .hour-color-container {
    display: flex;
    justify-content: space-between;
    .item {
      margin-left: 8px;
      width: 16px;
      height: 12px;
      border-radius: 2px;
      position: relative;
      .box {
        visibility: hidden;
      }
      &:hover > .box {
        visibility: visible;
      }
    }
  }
  .tips-desc {
    font-size: 12px;
    color: #646A73;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
  }
}

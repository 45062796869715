.flex_page {
  display       : flex;
  flex-direction: column;
  height        : 100vh;

  .page_container {
    flex    : 1;
    overflow: hidden;
  }

  .nav_tab_bar {
    padding        : 12px 24px;
    display        : flex;
    justify-content: space-between;

    .nav_link {
      a {
        margin-right: 36px;
        color       : #333;

        &.nav_link_active {
          color   : #3370FF;
          display : inline-block;
          position: relative;

          &::after {
            display         : block;
            content         : "";
            background-color: #3370FF;
            width           : 100%;
            height          : 2px;
            position        : absolute;
            bottom          : -12px;
          }
        }
      }
    }

    .nav-setting {}

  }
}
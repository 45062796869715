.department {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  .department-header {
    padding-top: 15px;
    background-color: #F5F6F7;
    .header-container {
      background-color: #FFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      .right-export {
        color:#FFF;
        border-radius: 1px;
        padding: 10px 19px;
        font-size: 16px;
        background-color: #3370FF;
        transition: all 0.3s ease-in-out;
        border-radius: 4px;
        box-shadow:0px 4px 10px 0px rgba(51,112,255,0.5);
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .department-container {
    background-color: #F5F6F7;
    padding: 0 16px 32px 16px;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
    .leader {
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;
      img {
        width: 45px;
      }
    }
    .table {
      .table-container {
        padding-top: 16px;
        .title-info {
          background-color: #FFF;
          padding: 24px 34px 0;
          .info-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              flex: 1;
              .all-member {
                font-size: 24px;
                color: #2B2F34;
                display: flex;
                box-sizing: border-box;
                align-items: flex-end;
                .ant-breadcrumb {
                  flex: 1;
                  padding-bottom: 5px;
                  margin-left: 5px;
                  .click {
                    color: #3370FF;
                    cursor: pointer;
                  }
                  .unclick {
                    color: #666;
                    pointer-events: none;
                  }
                }
                .all-icon {
                  transform: translateY(2px);
                  font-size: 0;
                  line-height: 1;
                }
                .icon-font {
                  font-size: 28px;
                }
                .all-text {
                  padding-left: 6px;
                  cursor: pointer;
                }
              }
              .current-total {
                margin-top: 8px;
                color: #646A73;
                font-size: 12px;
              }
            }
          }
        }
        .table-left {
          min-width: 260px;
        }
      }
    }
  }
}
.selector-picker-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  .custom-ant-select {
    flex: 1;
    border:solid 1px #dee0e3;
    border-radius: 4px;
    transition: all .3s ease-out;
    box-sizing: border-box;
    &:hover {
      border-color: #3370FF;
    }
    .ant-select-selector {
      height: 36px;
      border-color: #dee0e3;
      .ant-select-selection-item, .ant-select-selection-placeholder {
        line-height: 36px;
      }
      .ant-select-selection-search-input {
        height: 34px;
      }
    }
  }
  .selector-picker-unit {
    padding-left: 10px;
  }
}
.selector-picker {
  border: 1px solid #E4E5E7;
  border-radius: 4px;
  padding: 8px 11px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  .selector-picker-placeholder {
    color: #9CA2A9;
    flex: 1;
    font-size: 14px;
  }
  .selector-picker-selected {
    flex: 1;
  }
  .selector-picker-unit {
    color: #cecece;
    padding-right: 10px;
  }
  .more-container {
    transition: transform 0.3s ease-in-out;
    .more {
      width: 0;
      height: 0;
      border: 6px solid;
      border-color: #E4E5E7 transparent transparent;
      transform: translateY(25%);
    }
    .icon {
      font-size: 6px;
      color: #646A73;
    }
    &.rotate {
      transform: rotate(-180deg);
      transition: transform 0.3s ease-in-out;
    }
  }
  &:hover {
    border-color: #3370FF;
  }
}

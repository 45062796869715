.icon-font {
  font-family: "iconfont" !important;
}
.date-select {
  display: inline-flex;
  position: relative;
  .box {
    box-sizing: border-box;
    border: 1px solid #dee0e3;
    border-radius: 4px;
    background-color: #FFF;
    padding: 0px 9px;
    height: 38px;
    display: flex;
    align-items: center;
    color: #1F2329;
    font-size: 14px;
    font-weight: normal;
    &.center {
      margin: 0 8px;
    }
    &.week-btn {
      font-size: 14px;
      font-weight: normal;
      color: #1F2329;
      border-radius: 4px;
      border: 1px solid #dee0e3;
      transition: all .3s ease-out;
      margin-left: 8px;
      &:after {
        border: none;
      }
      &:hover, &.active {
        border-color: #3370FF;
      }
    }
    cursor: pointer;
  }
  .date-picker {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    .date-icon {
      margin-right: 8px;
    }
    .date-area {
      margin: 0 6px;
      min-width: 60px;
      text-align: center;
    }
    .date-popover {
      position: absolute;
      width: 430px;
      left: 0;
      top: 44px;
      background: #fff;
      box-shadow:0px 2px 4px 0px rgba(199,199,199,0.5);
      border-radius: 2px;
      z-index: 10;
      transition: all .3s ease-out;
      opacity: 0;
      visibility: hidden;
      transform: scaleY(0.5) translateY(-40px);
      transform-origin: 50% 0%;
      &.show {
        transform: scaleY(1)  translateY(0);
        visibility: visible;
        opacity: 1;
      }
      .head {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        .icon-font {
          color: #D8D8D8;
        }
        .date-month {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 34px;
          color: #1F2329;
        }
        .month-prev {
          margin-left: 20px;
        }
        .month-next {
          margin-right: 20px;
        }
      }
      .calendar-container {
        display: flex;
        .calendar {
          width: 50%;
          padding: 14px;
          .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2px;
            &.week {
              margin-bottom: 10px;
            }
            .item {
              flex: 1;
              height: 22px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              transition: all .3s ease-out;
              &.disabled {
                color: #9CA2A9;
              }
              &.select {
                background: #3370FF;
                color: #fff;
              }
              &.in-range {
                background: rgba(51,112,255, 0.13);
              }
              &.current {
                border: solid 1px #3370FF;
              }
            }
          }
        }
      }
      .footer {
        padding: 10px 20px;
        display: flex;
        justify-content: flex-end;
        border-top: solid 1px #F4F5F6;
        .action-btn {
          font-size: 14px;
          margin-left: 10px;
          &.cancel {
            color: #666
          }
          &.confirm {
            background: #3370FF;
            color: #fff;
            &:hover {
              opacity: 0.7;
            }
            &:after {
              border: none;
            }
          }
        }
      }
    }
  }
  .backgroud {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
  }
}

.icon-font {
  font-family: 'iconfont' !important;
  font-size: 16px;
}

.project-analyze-page {
  display: flex;
  flex-direction: column;
  background: #F5F6F7;

  .top-filter {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    margin-top: 16px;
    font-size: 0;
    .date-select {
      flex: 1;
    }
    .archive, .export {
      margin-right: 20px;
      font-size: 14px;
      color: #fff;
      background: #3370FF;
      box-shadow: 0px 4px 10px 0px rgba(51,112,255,0.5);
      border-radius: 4px;
      padding: 12px 15px;
      transition: opacity .3s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .project-analyze-container {
    flex: 1;
    background: #F5F6F7;
    overflow-y: auto;

    .content {
      margin-top: 40px;
      padding: 0 16px;
      .project-name {
        text-align: center;
        font-size: 36px;
        color: #1F2329;
        font-weight: bold;
        text-align: center;
        margin-bottom: 28px;
      }

      .total-view {
        display: flex;
        .item {
          flex: 1;
          margin-right: 16px;
          background-color: #fff;
          font-size: 16px;
          height: 116px;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 20px 0px rgba(228,228,228,0.5);
          &.project-info {
            align-items: flex-start;
            .line {
              margin-bottom: 6px;
              margin-left: 20px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }

          .number {
            font-size: 36px;
            color: #1F2329;
          }
          .label, .line {
            font-size: 14px;
            color: #646A73;
          }
        }
      }
    }

    .project-tables {
      margin-top: 16px;
      background-color: #fff;
      .project-info {
        display: flex;
        justify-content: space-between;
        padding: 50px 25px 25px 25px;
        .info-container {
          .name {
            font-size: 24px;
            color: #2B2F34;
            margin-bottom: 8px;
            .icon-font {
              font-family: "iconfont";
              font-size: 24px;
              margin-right: 7px;
            }
          }
          .datas {
            display: flex;
            .item {
              font-size: 12px;
              margin-right: 20px;
              color: #646a73;
              &.overtime {
                color: #F20000;
              }
            }
          }
        }
      }
    }
  }
}

.terminal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255);
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 5px 5px 20px rgba(80, 80, 80, 0.61);
  .input {
    width: 100%;
    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
    }
  }
  .output {
    width: 400px;
    height: 300px;
    overflow: auto;
    .log_item {
      font-size: 12px;
      margin-bottom: 0;
      .input {
        margin-bottom: 0;
        color: #333;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          background: #ddd;
          display: inline-block;
          clip-path: polygon(0% 0%, 70% 50%, 0% 100%);
          margin-right: 5px;
        }
      }
      .date {
        color: #888;
        margin-bottom: 0;
      }
      pre {
        border-left: solid 2px #ddd;
        padding-left: 10px;
      }
    }
  }
  .close {
    width: 20px;
    // padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
    img {
      width: 100%;
    }
  }
}
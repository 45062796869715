.base-contact-picker {
  padding: 24px 0 0 0;
  border-radius: 0px;
  font-size: 32px;
  padding: 8px 0 0 0;
  font-size: 32px;
  .contact-picker-container {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background .3s ease-out;
    &:hover {
      background: #f5f5f5;
    }
    .user {
      display: flex;
      flex-direction: column;
      margin: 0 12px 12px 0;
      justify-content: center;
      align-items: center;
      max-width: 50px;
      .avatar-content {
        position: relative;
        font-size: 0;
      }
      .remove {
        position: absolute;
        font-size: 0;
        padding: 10px;
        right: -10px;
        top: -10px;
        .content {
          background: #F54A45;
          border-radius: 50%;
          font-size: 0;
          width: 12px;
          height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .icon-font {
          font-family: 'iconfont';
          font-size: 8px;
          color: #fff;
        }
      }
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      .name {
        margin-top: 4px;
        font-size: 12px;
        color: #1f2329;
        text-align: center;
      }
    }
    .add {
      width: 32px;
      height: 32px;
      background-color: #f5f6f7;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-bottom: 12px;
      .icon {
        font-size: 15px;
        color: #D8D8D8;
        font-family: 'iconfont';
      }
    }
    .user-select {
      // position: absolute;
      z-index: 1;
      width: 100%;
      height: 32px;
      // overflow: hidden;
      left: 0;
      .ant-select-selection-item {
        pointer-events: none;
      }
    }
  }
}

.contact-item {
  display: flex;
  align-items: center;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .name {
    flex: 1;
    padding-left: 10px;
  }
}